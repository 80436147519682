import { storeToRefs } from 'pinia'
import { useProfileStore } from '~/stores/profileStore'

export const useProfile = () => {
  const profileStore = useProfileStore()
  const { isLoggedIn, getCiamId, getProfileId } = storeToRefs(profileStore)

  return {
    isLoggedIn,
    getCiamId,
    getProfileId
  }
}
